<template>
  <v-dialog
    :value="show"
    persistent
    width="500"
    @click:outside="cancel"
    @keydown="handleKeydown"
  >
    <v-card>
      <v-card-title>Benutzer löschen?</v-card-title>

      <v-card-text>
        Wollen Sie den Benutzer {{ name }} wirklich löschen?
        Diese Aktion kann nicht rückgängig gemacht werden.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <temeno-circle-button action title="abbrechen" @click="cancel">
          $close
        </temeno-circle-button>

        <temeno-circle-button
          action
          title="Benutzer löschen"
          type="submit"
          @click="$emit('submit')"
        >
          $approve
        </temeno-circle-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteUserDialog",
  components: {
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    name() {
      return this.user ? `„${this.user.name}“` : "";
    },
    show() {
      return this.user != null;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    handleKeydown(key) {
      if (key.code === "Escape") {
        this.cancel();
      }
    },
  },
};
</script>
